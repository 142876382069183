/**
 * Regular expression to validate a password.
 * - Must contain at least one digit
 * - Must contain at least one special character (!@#$%^&*)
 * - Must contain at least one letter (uppercase or lowercase)
 * - Must be at least 8 characters long
 */
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;

/**
 * Regular expression to match any special character from the set: !, @, #, $, %, ^, &.
 */
export const SPECIAL_CHARACTER_REGEX = /[!@#$%^&]/;

/**
 * Regular expression to match any number.
 */
export const NUMBER_REGEX = /\d/;

/**
 * Matches valid email addresses.
 *
 * This regular expression allows standard email formats, such as:
 * - Alphanumeric characters before the "@" symbol
 * - Special characters like dots (.) within the local part
 * - A valid domain with alphanumeric characters and hyphens, followed by a top-level domain (e.g., ".com", ".org")
 * - Allows IPv4 address domains within square brackets
 */
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Matches any path that does not start with "/api".
 */
export const API_MATCHER = /^\/((?!api).*)/;

/**
 * Matches paths that include "/<anyword>/home", such as "/user/home".
 */
export const HOME_MATCHER = /\/\w+\/home.*/;

/**
 * Matches paths that exactly match "/home/my-orders" or "/home/my-orders/".
 */
export const MY_ORDERS_MATCHER = /\/home\/my-orders(\/)?$/;

/**
 * Matches paths that exactly match "/home/track-and-trace/a2g9b000000XLDlAAO" or "/home/track-and-trace/a2g9b000000XLDlAAO?andercore_id=IM-191919191".
 */
export const TRACK_AND_TRACE_MATCHER = /\/home\/track-and-trace\/[^/]+$/; // TODO: After December 15th 2024, remove this matcher and related code.

/**
 * Matches paths that exactly match "/home/settings" or "/home/settings/".
 */
export const SETTINGS_MATCHER = /\/home\/settings(\/)?$/;

/**
 * data/salesforce/documents/fulfillment-events/a2g9V000000dXLtQAM/Delivery Note FE-0689V000005Yl9hQAC.pdf
 * Gets the name of the document from the key.
 * - Delivery Note FE-0689V000005Yl9hQAC.pdf
 */
export const NAME_OF_DOCUMENT_REGEX = /\/([\w-]+)\.pdf$/; // This fetches the name of the document from the key
